import { useState } from "react";
import "../Styles/ReadMore.css";

const ReadMore = ({text, maxLength}) => {
    const[isCollapsed, setIsCollapsed]= useState(true);

    let toggleCollapse=()=>{
        setIsCollapsed(!isCollapsed);
    }


    return ( 
        <div className="read-more-container">
            <img src="./media/partner1.jpeg" alt="" />
            <div className="text-container">
                <h1>About Tyche</h1>
                <h2>We Support Your Staffing Needs</h2>
                <p className="para">
                    {isCollapsed? text.slice(0, maxLength): text}
                    {text.length > maxLength && (
                        <button onClick={toggleCollapse}>
                            {isCollapsed? '..Read More' : 'Show Less'}
                        </button>
                    )}
                </p>
            </div>
        </div>
     );
}
 
export default ReadMore;