import "../Styles/ServiceCardMobile.css";

const ServiceCardMobile = ({imageSrc, title, content, listItems}) => {
    return ( 
        <div className="cardMobile">
                <img className="image-box" src={imageSrc} alt={title} />
            <div className="cardMobile-content">
                <h3>{title}</h3>
                <p>{content}</p>
                {listItems && listItems.length > 0 && (
                    <ul>
                        {listItems.map((item, index) => (
                            <li key={index}> {item} </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>    
     );
}
 
export default ServiceCardMobile;