import "../Styles/AboutUsVisionMission.css";
import { useEffect } from "react";
import { useState } from "react";

const AboutUsVisionMission = () => {
    let [showVision, setShowVision] = useState(true);

    let toggleVisionMission = () => {
        setShowVision((prevShowVision) => !prevShowVision);
    }

    useEffect(()=>{
        let interval = setInterval(toggleVisionMission,5000);
        return () => clearInterval(interval);
    },[]);

    return ( 
        <div className="vision-mission-container">
            {showVision && (
                <div className="vision">
                    <h1>Vision</h1> 
                    <p>Tyche's vision is to provide cost-effective, efficient and faster software solutions to the end clients. It pioneers in full stack development of software products for various business needs.</p>
                </div>
        )}
    
            {!showVision && (
                <div className="mission">
                    <h1>Mission</h1> 
                    <p>Our mission is to be a global leader in software services, training, recruitment, staffing, solutioning and innovations</p>
                </div>
            )}  
        </div>
     );
}
 
export default AboutUsVisionMission;