import { useEffect } from "react";
import { useState } from "react";
import "../Styles/Header.css";

const Header = ({title}) => {
    let [scrollPosition, setScrollPosition] = useState(0);

    let handleScroll=()=>{
        setScrollPosition(window.scrollY);
    }

    useEffect(()=>{
        window.addEventListener(
            "scroll", handleScroll, { passive:true}
        );

        return ()=>{
            window.removeEventListener("scroll", handleScroll);
        }
    },[]);

    return ( 
        <div
            style={{
                backgroundSize: `${(window.outerHeight - scrollPosition)/3}%`
            }}
            className="header-container"
        >
            <h1>{title}</h1>
        </div>
     );
}
 
export default Header;