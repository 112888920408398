import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook,faClipboardList,faHome,faUser,faAngleRight,faCheck} from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import "../Styles/Footer.css";
import { useState } from "react";

const Footer = () => {

    let [email, setEmail]= useState('');
    let [icon, setIcon] = useState('first')

    let handleSubscription=(e)=>{
        e.preventDefault();

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({email}),
          redirect: 'follow'
        };
        
        fetch(`http://localhost:8080/subscriber/create?email=${email}`, requestOptions)
            .then((result) => {
                if(!result.ok){
                    throw new Error("Failed to subscribe");
                }
                    return result.json();
            })

            .then((data) => {
                console.log(data.data.alreadyPresent)
                if(data.data.alreadyPresent===true){
                    alert("You have already subscribed")
                    setEmail('')
                }
                else{
                    setIcon('second');
                    alert("Thank You for Subscribing");
                    setTimeout(() => {
                        setIcon('first');
                    }, 5000);
                    setEmail('');
                }
            })
  
                .catch(error => {
                    console.log('error', error)
                    alert("Enter your email to subscribe")
                });
    }

    return ( 
        <footer>
            <div className="footer-wrapper">
                <div className="footer-section"> 

                    <div className="footer-subscribe">               
                        <img className="tychelogo" src="./media/tychelogo.jpeg" alt="" />         
                        <h2>Subscribe to Tyche Software newsletter to stay up-to-date. </h2>

                        <div className="input-box">
                            <input type="email" id="" placeholder="Email Address" value={email} onChange={(e)=>setEmail(e.target.value)} />
                            <button onClick={handleSubscription}>
                                {icon=== 'first'? <FontAwesomeIcon icon={faAngleRight}/>: <FontAwesomeIcon icon={faCheck}/>}
                            </button>
                        </div>
                    </div>

                    <nav className="footer-nav">
                        <div>
                            <h3>Quick Links</h3>
                            <ul>
                                <li>
                                    <FontAwesomeIcon icon={faHome}/>
                                    <Link to='/'>Home</Link>
                                </li>

                                <li>
                                    <FontAwesomeIcon icon={faClipboardList}/>
                                    <Link to='/aboutus'>About-Us</Link>
                                </li>

                                <li>
                                    <FontAwesomeIcon icon={faUser}/>
                                    <Link to='/service'>Services</Link>
                                </li>

                                <li>
                                    <FontAwesomeIcon icon={faAddressBook}/>
                                    <Link to='/contact'>Contact-Us</Link>
                                </li>
                            </ul>
                        </div>

                        
                        <div>
                            <h3>Stay In Touch</h3>
                            <ul>
                                <li>
                                    <FontAwesomeIcon icon={faFacebookF}/>
                                    <Link to='https://www.facebook.com/?sk=welcome'>FaceBook</Link>
                                </li>

                                <li>
                                    <FontAwesomeIcon icon={faLinkedin}/>
                                    <Link to='https://www.linkedin.com/feed/'>LinkedIn</Link>
                                </li>

                                <li>
                                    <FontAwesomeIcon icon={faTwitter}/>
                                    <Link>Twitter</Link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>

                <div class="footer-copyright">
                    <p>©2023 TycheSoftware, Inc. All rights reserved.</p>
                    <p>Privacy Policy.</p>
                    <p>Terms of Use.</p>
                </div>
            </div>
        </footer>
     );
}
 
export default Footer;