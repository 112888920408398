import '../Styles/Services.css';
import Header from "./Header";
import ServiceCard from "./ServiceCard";
import ServiceCardMobile from './ServiceCardMobile';

const Services = () => {

    let cardData=[
        {
            imageSrc: "./media/cloudcomputing.jpeg",
            title: "Cloud Computing",
            content:"Tyche management has good exposure and experience in large scale Digital engineering transformation from On Prem data servers to Cloud based (AWS, Azure & Open source) cloud solutions. Tyche can establish the below for any given start up with a minimal schedule.",
            listItems:["SaaS: Software as a service", "PaaS: Platform as a service", "IaaS: Infrastructure as a service"]
        },
        {
            imageSrc: "./media/projectmanagement.jpeg",
            title: "Project Management",
            content:"Tyche comes with seasoned professionals in extensive project management and have experience in various methodologies including Agile, Scrum, V-model & Waterfall.",
            listItems:["Strategy Transformation", "Tools, Methodologies and Frameworks","Process management","Project Management","Product development"]
        },
        {
            imageSrc: "./media/qualityengineering.jpeg",
            title: "Quality Engineering",
            content:"Tyche software can tailor various Quality services to the corporates for reducing the cost of Quality, Automation, Increasing the performance of the softwares along with high security.Tyche is specialized in :",
            listItems:["Automation Testing", "Performance testing", "Security Testing", "Project Management", "Product development"]
        },
        {
            imageSrc: "./media/softwaredevelopment.jpeg",
            title: "Software Development",
            content:"Tyche have proven experience in developing web applications, client-server applications, multi tier architectures, graphical user interfaces, web development and distributed applications along with mainframes.Tyche's expertise:",
            listItems:["Open Source technology", ".NET, Java/J2EE", "Mobile / Android / iOS", "Ajax / BizTalk / GIS", "Microsoft Sharepoint / Dynamics", "Web Application Development", "Business Intelligence and Data Warehousing", "Custom Application Development and Integration", "e-Business Integration and ERP solutions", "Testing and QA"]
        }
    ]

    return ( 
        <>
            <div className="service-container">
                <Header title="IT Consulting Services"/>
                    <div className="card-container">
                        <div className='desktop-view'>
                        {
                            cardData.map((data,index) => (
                                <ServiceCard
                                    key={index}
                                    imageSrc={data.imageSrc}
                                    title={data.title}
                                    content={data.content}
                                    listItems={data.listItems}
                                />
                            ))
                        }
                        </div>

                        <div className='mobile-view'>
                            {
                                cardData.map((data,index) => (
                                    <ServiceCardMobile
                                    key={index}
                                    imageSrc={data.imageSrc}
                                    title={data.title}
                                    content={data.content}
                                    listItems={data.listItems}
                                    />
                                ))
                            }
                           
                        </div>
                        
                    </div>
              
            </div>
        </>
        
     );
}
 
export default Services;