import "../Styles/AboutUs.css";
import Header from "./Header";

import AboutUsInfo from "./AboutUsInfo";
import AboutUsCoreValues from "./AboutUsCoreValues";
import AboutUsVisionMission from "./AboutUsVisionMission";

const AboutUs = () => {
    return (
            <div className="aboutus-container">
                <Header title="About Us"/>
                <AboutUsInfo/>
                <AboutUsVisionMission/>
                <AboutUsCoreValues/>             
            </div>
     );
};
 
export default AboutUs;