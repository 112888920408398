import { Link, useLocation } from "react-router-dom";
import "../Styles/Navbar.css";
import { useState } from "react";
import { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
    const [activeItem, setActiveItem]= useState('item1');
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [isMenuOpen, setIsMenuOpen]= useState(false);
    const location = useLocation();
    
    const handleItemClick=(itemName, index)=>{
        setActiveItem(itemName);
    };

    useEffect(()=>{
        switch (location.pathname) {
            case "/":
                setActiveItem("item1");
                break;
            case "/aboutus":
                setActiveItem("item2");
                break;
            case "/service":
                setActiveItem("item3");
                break;
            case "/contact":
                setActiveItem("item4");
                break;
            default:
                setActiveItem("");
        }
    }, [location.pathname]);

    let handleMenuClick=()=>{
        setIsMenuOpen(!isMenuOpen);
    }

    useEffect(()=>{
        const handleResize=()=>{
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener("resize", handleResize)

        return()=>{
            window.removeEventListener("resize", handleResize)
        }
    },[])

    return ( 
        <div className="nav-container">
            <div className="navbar">
                <img className="logo" src="./media/tychelogo.jpeg" alt="" />
                {screenWidth >=900 ? (
                    <nav className="navigation">
                        <ul className="menu">
                            <li className= {`list item1 ${activeItem === 'item1' ? 'active' : ''}`} onClick={() => handleItemClick('item1')}>  
                                <Link to="/">
                                    <span className="text">Home</span>
                                </Link> 
                            </li>

                            <li className={`list item2 ${activeItem === 'item2' ? 'active' : ''}`} onClick={() => handleItemClick('item2')}> 
                                <Link to="/aboutus">
                                    <span className="text">About Us</span>
                                </Link> 
                            </li>

                            <li className={`list item3 ${activeItem === 'item3' ? 'active' : ''}`} onClick={() => handleItemClick('item3')}> 
                                <Link to="/service">
                                    <span className="text">Services</span>
                                </Link> 
                            </li>

                            <li className={`list item4 ${activeItem === 'item4' ? 'active' : ''}`} onClick={() => handleItemClick('item4')}> 
                                <Link to="/contact">
                                    <span className="text">Contact Us</span>
                                </Link> 
                            </li>
                        </ul>
                    </nav>
                ) : (
                    <nav className="navbar2">
                        <div className="hamburger-menu" onClick={handleMenuClick}>
                            <FontAwesomeIcon icon={isMenuOpen? faTimes : faBars}/>
                        </div>
                            {
                            isMenuOpen && (
                            <ul className="menu-items">
                            <li className= {`list item1 ${activeItem === 'item1' ? 'active' : ''}`} onClick={() => handleItemClick('item1')}>  
                                <Link to="/">
                                    <span className="text">Home</span>
                                </Link> 
                            </li>

                            <li className={`list item2 ${activeItem === 'item2' ? 'active' : ''}`} onClick={() => handleItemClick('item2')}> 
                                <Link to="/aboutus">
                                    <span className="text">About Us</span>
                                </Link> 
                            </li>

                            <li className={`list item3 ${activeItem === 'item3' ? 'active' : ''}`} onClick={() => handleItemClick('item3')}> 
                                <Link to="/service">
                                    <span className="text">Services</span>
                                </Link> 
                            </li>

                            <li className={`list item4 ${activeItem === 'item4' ? 'active' : ''}`} onClick={() => handleItemClick('item4')}> 
                                <Link to="/contact">
                                    <span className="text">Contact Us</span>
                                </Link> 
                            </li>
                        </ul>
                            )}
                    </nav>
                )
            }
                
            </div>
        </div>
     );
}
 
export default Navbar;