import "../Styles/AboutUsCoreValues.css";
import { useEffect } from "react";
import { useState } from "react";

const CenterCircle = () => {
    return(
        <div className="center-circle">
            Core Values
        </div>
    );
};

const Circle = ({id, angle, text, color }) => {

    return (
        <div
            className={`circles circle-${id}`}
            style={{
                transform: `rotate(${angle}deg) translate(150px) rotate(-${angle}deg)`,
                backgroundColor: color
            }}
            >
            {text}
        </div>
    );
  };

const AboutUsCoreValues = () => {
    let [circles, setCircles]= useState([
        {id: 1 , color: '#ff8398', text:'Teamwork'},
        {id: 2 , color: '#ffa382', text:'Youthfulness'},
        {id: 3 , color: '#fff188', text:'Commitment'},
        {id: 4 , color: '#8b7edb', text:'Humility'},
        {id: 5 , color: '#9ed39e', text:'Exuberance'}
    ]);

    useEffect(()=>{
        let interval = setInterval(()=>{
            setCircles(prevCircles => {
                let newCircles= [...prevCircles];
                let lastCircle = newCircles.pop();
                newCircles.unshift(lastCircle);
                return newCircles;
            });
        }, 20000);

        return () => clearInterval(interval);
    },[])

    return ( 
        <div className="core-values">
        <CenterCircle/>
        {circles.map(circle => (
             <Circle
                 key={circle.id}
                 id={circle.id}
                 angle={(circle.id -1) * (360/circles.length)}
                 color={circle.color}
                 text={circle.text}
             />
        ))}
     </div>
     );
}
 
export default AboutUsCoreValues;