import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Components/Home';
import AboutUs from './Components/AboutUs';
import Services from './Components/Services';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import Contact from './Components/Contact';

function App() {
  return (
    <BrowserRouter>             
      <Navbar/>
      <div className="App">
          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path='/aboutus' element={<AboutUs/>}/>
            <Route path='/service' element={<Services/>}/>
            <Route path='/contact' element={<Contact/>}/>
          </Routes>
      </div>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
