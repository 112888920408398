import '../Styles/Home.css';
import Header from './Header';
import MovingPanels from './MovingPanels';
import ReadMore from './ReadMore';

const Home = () => {
    const paragraph="Tyche Software understands the critical role that talented software professionals play in driving innovation and success for businesses.That is why we are dedicated to connecting companies with top-tier software developers, engineers, designers, and IT specialists to meet their specific project requirements and objectives.In a world increasingly reliant on digital collaboration, the emergence of innovative software solutions is crucial for enhancing productivity and connectivity.Tyche is poised to revolutionize the way teams collaborate and communicate.Tyche is a cutting-edge software started by a team of seasoned industry professionals with a shared vision of transforming teamwork through technology.";

    return ( 
        <>
            <div className='home-container'>
                <div>
                <Header title="Tyche Software"/>
                <MovingPanels/>
                <ReadMore text={paragraph} maxLength={paragraph.length}/>
                </div>
                
            </div>
            {/* <Footer/> */}
        </>
        
     );
}
 
export default Home;