import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react";
import '../Styles/MovingPanels.css';

const Testimonial = ({item, isActive, opacity}) =>(
  <div className={`testimonial ${isActive? "active": "inactive"}`} style={{opacity}}>
    <h2>{item.heading}</h2>
    <p>{item.text}</p>
  </div>
)

const MovingPanels = () => {

  //state for managing carousel
  let [state, setState] = useState({
    currentSlide:0,
    currentActive:0,
    inactiveTestimonialOpacity:1
  });

  //Destructure state values for easy accesss
  let {currentSlide,currentActive,inactiveTestimonialOpacity} = state;

  //Animation duration for transition
  let animationDuration = 500;

  useEffect(()=>{
    if(currentActive!== currentSlide){
      setState((prevState)=>({
        ...prevState,
        inactiveTestimonialOpacity:0
      }));

      const timeoutId= setTimeout(() => {
        setState((prevState)=>({
          ...prevState,
          inactiveTestimonialOpacity:1
        }));
      }, animationDuration);

      return ()=> clearTimeout(timeoutId);
    }
  },[currentActive, currentSlide, animationDuration])

  // Function to handle slide changes
  let playSlide=(slide)=>{
    // Update currentSlide and currentActive in the state
    setState((prevState)=>({
      ...prevState,
      currentActive:slide,
      currentSlide:slide
    }));

    // Wrap around if reaching the last or first slide
    if(slide < 0) slide = currentSlide = testimContent.length-1
    if(slide > testimContent.length-1) slide = currentSlide = 0

    // Perform opacity transition for testimonial content
    if(currentActive!==currentSlide){
      setState((prevState)=>({
        ...prevState,
        inactiveTestimonialOpacity:0
      }));

      setTimeout(() => {
        setState((prevState)=>({
          ...prevState,
          inactiveTestimonialOpacity:1
        }));
      }, animationDuration);
    }
  }
 
  // Function to handle arrow clicks
  let handleArrowClick=(direction)=>{
      const newSlide= direction === "left" ? currentSlide - 1 : currentSlide + 1;
      playSlide(newSlide);
  }

  // Function to handle dot clicks
  let handleDotClick=(index)=>{
    playSlide(index)
  }

  // Testimonial data
  let testimContent=[
    {
      heading:'Industry Expertise',
      text :'With years of experience in the software staffing industry, we have a deep understanding of the technical requirements and challenges faced by businesses across various sectors.'
    },
    {
      heading:'Quality Assurance',
      text :'We prioritize quality in everything we do, from candidate selection to client support.Our stringent screening process ensures that you receive top-notch professionals who are capable of delivering exceptional results'
    },
    {
      heading:'Efficiency and Speed',
      text :'We recognize the importance of time-to-market in todays fast-paced business environment. Our efficient recruitment process allows us to quickly identify and onboard the right talent, minimizing downtime and accelerating project timelines.'
    },
    {
      heading:'Cost-Effective Solutions',
      text :'We offer competitive pricing and cost-effective staffing solutions without compromising on quality.Our goal is to help you maximize your ROI by providing access to top talent at affordable rates.'
    }
  ]

    return ( 
      // Testimonial Carousel Section
        <section id="testim" className="testim">
          <div className="wrap">
            {/* Left and Right arrows for navigation */}
            <span
              id="right-arrow"
              className="arrow right"
              onClick={() => handleArrowClick("right")}
            >
              <FontAwesomeIcon icon={faChevronRight} className="rltar"/>
            </span>

            <span
              id="left-arrow"
              className="arrow left"
              onClick={() => handleArrowClick("left")}
            >
              <FontAwesomeIcon icon={faChevronLeft} className="rltar"/>
            </span>

            {/* Dots for navigation */}
            <ul id="testim-dots" className="dots">
              {testimContent.map((item,index)=>(
                <li className={`dot ${index === currentSlide ? "active" : ""}`} onClick={() => handleDotClick(index)}></li>
              ))}
            </ul>

             {/* Testimonial Content Container */}
            <div id="testim-content" className="cont">
              {testimContent.map((item, index) => (
              // Render Testimonial component for each testimonial object
              <Testimonial
                key={index}
                item={item}
                isActive={index === currentSlide}
                opacity={
                  index === currentActive ? 1 : inactiveTestimonialOpacity
                }
              />
              ))}
            </div>
          </div>
        </section>
     );
}
 
export default MovingPanels;