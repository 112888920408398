import "../Styles/ContactForm.css";
import { useState } from "react";

const ContactForm = () => {
    let [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email:'',
        phone:'',
        message: ''
    })

    let handleChange =(e)=>{
        const{name, value} = e.target;
        setFormData({...formData, [name]: value});
    }

    let handleSubmit= async(e) => {
        e.preventDefault();

        try{
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify(formData),
                redirect: "follow"
            };
              
            const response= await fetch("http://localhost:8080/user/add", requestOptions)
            
            if(!response.ok){
                throw new Error('Failed to submit contact form')
            }
            alert('Form Submitted successfully')
        }
        catch(error){
            console.error('Error submitting form:', error.message);
            alert('We already have your information saved. We will get back to you soon');
        }
    }

    return ( 
        <div className="form-container">
            <h1 className="form-head">Get In Touch</h1>
            <form className="form-content" onSubmit={handleSubmit}>
                <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} placeholder="First name" className="form-input" required/>
                <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} placeholder="Last name" className="form-input" required/>
                <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email" className="form-input" required/>
                <input type="tel" name="phone" value={formData.phone} onChange={handleChange} placeholder="Phone" className="form-input" required/>
                <textarea name="message" value={formData.message} onChange={handleChange} cols="120" rows="10" placeholder="Message" className="form-textarea"></textarea>
                <input type="submit" value="Submit" className="submit-button"/>
            </form>
        </div>
        
     );
}
 
export default ContactForm;