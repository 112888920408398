import "../Styles/AboutUsInfo.css"

const AboutUsInfo = () => {
    return ( 
        <div className="aboutus">
            <div className="info">
                <h1>Tyche Software</h1>
                    <p>
                        Tyche software started is an Software Consulting firm with a panoramic vision and with a great enthusiasm, in the year 2023. With a wider IT industry experience and extensive software methodologies, the founder started the company to cater to various IT and Business needs.
                    </p>
                    <p>
                        Core strengths of the company include Software solutioning, Software Product development, Training , IT Staffing, and large Transformations.Tyche professionals excel in transforming the overarching vision of large corporates in to reality.
                    </p>
                    <p>
                        The team of professionals can assist you in all the life cycle stages. From Idea generation to Requirements and from Design to software development Tyche provides all the real time needs.
                    </p>
                    <p>
                        We are confident in ramping up the teams from various technologies with strong IT professionals with strong acumen and business domain knowledge. The core competency is to deliver with Stellar speed and with impeccable quality and accuracy in meeting all milestones.
                    </p>
            </div>

            <div className="aboutus_img">
                <img src="./media/softwareprof.jpeg" alt="" />
            </div>
        </div>
    );
}
 
export default AboutUsInfo;