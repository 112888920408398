import ContactCard from "./ContactCard";
import "../Styles/Contact.css";
import Header from "./Header";
import ContactForm from "./ContactForm";

const Contact = () => {

    const contactDetails1 = {
        name: 'Tyche Software',
        email: 'kriss@tychesoftware.com',
        phone: '+1(952) 652-1286',
        address: '7150 Cahill Road, Edina, Minnesota-55439, USA',
    }

    const contactDetails2 = {
        name: 'Tyche Software',
        email: 'kriss@tychesoftware.com',
        phone: '+91 9948799197',
        address: 'Hno: 5-35-8/1, Street 3/18 Brodipet Guntur, Andhra Pradesh, India  500002',
    }

    return ( 
        <>
            <div className="contact-container">
                <Header title="Contact Us"/>

                <div className="contact-cards">
                    <ContactCard
                        contactName={contactDetails1.name}
                        contactEmail={contactDetails1.email}
                        contactPhone={contactDetails1.phone}
                        contactAddress={contactDetails1.address}
                    />

                    <ContactCard
                        contactName={contactDetails2.name}
                        contactEmail={contactDetails2.email}
                        contactPhone={contactDetails2.phone}
                        contactAddress={contactDetails2.address}
                    />
                </div>
                
                <ContactForm/>
            </div>
          
        </>
     );
}
 
export default Contact;