import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CookieConsent from 'react-cookie-consent';
import { BrowserRouter } from 'react-router-dom';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <CookieConsent
        cookieName='TycheSoftwareCookie'
        location='bottom'
        buttonText='Accept'
        declineButtonText='Decline'

        declineButtonStyle={{
          "padding": "12px 32px",
          "borderRadius":"4px",
          "background-color":"red"
        }}

      >
        <span>This website uses cookies to enhance user experience</span>
      </CookieConsent>
    </BrowserRouter>
    <App />
  

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
