import { motion } from "framer-motion";
import "../Styles/ContactCard.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faLocationDot } from '@fortawesome/free-solid-svg-icons';


const ContactCard = ({contactName, contactEmail, contactPhone, contactAddress}) => {
    return ( 
        <motion.div className="contactCard">
            <div className="contactCard-content">
                <h2 className="contact-name">
                    {contactName}
                </h2>

                <div className="contact-info">
                    <FontAwesomeIcon icon={faEnvelope}/>
                    <span>
                        {contactEmail}
                    </span>
                </div>

                <div className="contact-info">
                    <FontAwesomeIcon icon={faPhone}/>
                    <span>
                        {contactPhone}
                    </span>
                </div>

                <div className="contact-info">
                    <FontAwesomeIcon icon={faLocationDot}/>
                    <span>
                        {contactAddress}
                    </span>
                </div>
            </div>
        </motion.div>
     );
}
 
export default ContactCard;