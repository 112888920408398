import "../Styles/ServiceCard.css";

const ServiceCard = ({imageSrc, title, content, listItems}) => {
    return ( 
            <div className="card">
            <img className="background" src={imageSrc} alt={title} />
                <div className="card-content">
                    <h3>{title}</h3>
                    <p>{content}</p>
                        {listItems && listItems.length > 0 && (
                            <ul>
                                {listItems.map((item, index) => (
                                <li key={index}>{item}</li>
                                ))}
                            </ul>
                        )}
                </div>
                <div className="backdrop"></div>
            </div>     
     );
}
 
export default ServiceCard; 